import React from "react";
import _ from "lodash";

import { Link, SocialLink, safePrefix } from "../utils";

export default class Header extends React.Component {
  closeMenu() {
    setTimeout(() => document.body.classList.remove("menu--opened"), 100);
  }

  render() {
    const sortedPages = _.get(this.props, "pageContext.pages")
      .filter(page => _.get(page, "name") !== "index")
      .sort(
        (a, b) => _.get(a, "frontmatter.order") - _.get(b, "frontmatter.order")
      );

    return (
      <header id="masthead" className="site-header outer">
        <div
          className="site-header-overlay"
          onClick={e => {
            document.body.classList.remove("menu--opened");
            e.preventDefault();
          }}
        />
        <div className="inner">
          <div className="site-header-inside">
            <div className="site-branding">
              {_.get(
                this.props,
                "pageContext.site.siteMetadata.header.logo_img"
              ) && (
                <p className="site-logo">
                  <Link to={safePrefix("/")}>
                    <img
                      src={safePrefix(
                        _.get(
                          this.props,
                          "pageContext.site.siteMetadata.header.logo_img"
                        )
                      )}
                      alt="Logo"
                    />
                  </Link>
                </p>
              )}
              <h1 className="site-title">
                <Link to={safePrefix("/")}>
                  {_.get(
                    this.props,
                    "pageContext.site.siteMetadata.header.title"
                  )}
                </Link>
              </h1>
            </div>
            <React.Fragment>
              <nav
                id="main-navigation"
                className={`site-navigation outer items-${
                  _.get(this.props, "pageContext.pages").filter(
                    page => _.get(page, "name") !== "index"
                  ).length
                }`}
                aria-label="Main Navigation"
              >
                <div className="site-nav-inside inner">
                  <div className="site-branding">
                    {_.get(
                      this.props,
                      "pageContext.site.siteMetadata.header.logo_img"
                    ) && (
                      <p className="site-logo" onClick={this.closeMenu}>
                        <Link to={safePrefix("/")}>
                          <img
                            src={safePrefix(
                              _.get(
                                this.props,
                                "pageContext.site.siteMetadata.header.logo_img"
                              )
                            )}
                            alt="Logo"
                          />
                        </Link>
                      </p>
                    )}
                    <h1 className="site-title" onClick={this.closeMenu}>
                      <Link to={safePrefix("/")}>
                        {_.get(
                          this.props,
                          "pageContext.site.siteMetadata.header.title"
                        )}
                      </Link>
                    </h1>
                  </div>
                  <button
                    id="menu-close"
                    className="menu-toggle"
                    onClick={e => {
                      document.body.classList.toggle("menu--opened");
                      e.preventDefault();
                    }}
                  >
                    <span className="screen-reader-text">Open Menu</span>
                    <span className="icon-close" aria-hidden="true" />
                  </button>
                  <div className="site-nav-content">
                    <div className="contact">
                      <ul className="menu social-links">
                        {_.map(
                          _.get(
                            this.props,
                            "pageContext.site.data.social.links"
                          ),
                          social => (
                            <li
                              key={JSON.stringify(social)}
                              className="menu-item menu-item-social"
                            >
                              <SocialLink
                                type={_.get(social, "type")}
                                url={_.get(social, "url")}
                                title={_.get(social, "title")}
                              />
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                    <ul className="menu">
                        {_.map(sortedPages, (item, item_idx) => {
                            return (
                              <li
                                key={item_idx}
                                className={
                                  "menu-item " +
                                  (_.get(this.props, "pageContext.url") ===
                                  _.get(item, "url")
                                    ? " current-menu-item"
                                    : "")
                                }
                                onClick={this.closeMenu}
                              >
                                <Link to={safePrefix(_.get(item, "url"))}>
                                  {_.get(item, "frontmatter.menu_label")}
                                </Link>
                              </li>
                            );
                        })}
                    </ul>
                  </div>
                </div>
              </nav>
              <button
                id="menu-open"
                className="menu-toggle"
                onClick={e => {
                  document.body.classList.toggle("menu--opened");
                  e.preventDefault();
                }}
              >
                <span className="screen-reader-text">Close Menu</span>
                Menu
              </button>
            </React.Fragment>
          </div>
        </div>
      </header>
    );
  }
}
