import React from "react";
import _ from "lodash";
import { Layout, ProjectHero, ProjectContent } from "../components/index";
import { Link } from "../utils";

export default class Project extends React.Component {
  render() {
    const currentPageOrder = _.get(this.props, "pageContext.frontmatter.order");

    // Need to account for home page which is why -2
    const nextPageOrder =
      currentPageOrder >= _.get(this.props, "pageContext.pages").length - 2
        ? 0
        : currentPageOrder + 1;

    const nextPage = _.get(this.props, "pageContext.pages").filter(
      page => _.get(page, "frontmatter.order") === nextPageOrder
    )[0];

    const heroSections = _.get(
      this.props,
      "pageContext.frontmatter.sections"
    ).filter((section, i) => i < 2);
    const heroProps = {
      heroTitle: heroSections[0].title,
      heroSubtitle: heroSections[0].subtitle,
      heroContent: heroSections[0].content,
      heroImage: heroSections[1].content_img_path,
      heroImageAltText: heroSections[1].alt_text
    };

    const containerSections = _.get(
      this.props,
      "pageContext.frontmatter.sections"
    ).filter((section, i) => i >= 2);

    const nextHeroSections = _.get(nextPage, "frontmatter.sections").filter(
      (section, i) => i < 2
    );
    const nextHeroProps = {
      heroTitle: nextHeroSections[0].title,
      heroSubtitle: nextHeroSections[0].subtitle,
      heroContent: nextHeroSections[0].content,
      heroImage: nextHeroSections[1].content_img_path,
      heroImageAltText: nextHeroSections[1].alt_text
    };

    return (
      <Layout {...this.props}>
        <ProjectHero truncated={false} {...heroProps} />
        <ProjectContent sections={containerSections} />

        <Link to={_.get(nextPage, "url")}>
          <div className="next-project-heading outer block">
            <div className="inner">
              <h3>Next Project</h3>
            </div>
          </div>

          <ProjectHero truncated {...nextHeroProps} />
        </Link>
      </Layout>
    );
  }
}
