import Header from "./Header";
import ProjectHero from "./ProjectHero";
import ProjectContent from "./ProjectContent";
import ProjectHeader from "./ProjectHeader";
import ProjectImage from "./ProjectImage";
import DoubleImage from "./DoubleImage";
import ProjectText from "./ProjectText";
import Layout from "./Layout";

export {
  Header,
  ProjectHero,
  ProjectContent,
  ProjectHeader,
  ProjectImage,
  DoubleImage,
  ProjectText,
  Layout
};

export default {
  Header,
  ProjectHero,
  ProjectContent,
  ProjectHeader,
  ProjectImage,
  DoubleImage,
  ProjectText,
  Layout
};
