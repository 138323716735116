import React from "react";
import { Helmet } from "react-helmet";
import _ from "lodash";
import favicon from "../favicon.ico";

import { safePrefix } from "../utils";
import Header from "./Header";

export default class Body extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <link rel="icon" href={favicon} />
          <title>
            {_.get(this.props, "pageContext.frontmatter.title") &&
              _.get(this.props, "pageContext.frontmatter.title") + " - "}
            {_.get(this.props, "pageContext.site.siteMetadata.title")}
          </title>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="google" content="notranslate" />
          <link rel="stylesheet" href={safePrefix("assets/css/main.css")} />
        </Helmet>
        <div id="page">
          <Header {...this.props} />
          <main id="content" className="site-content">
            {this.props.children}
          </main>
        </div>
      </React.Fragment>
    );
  }
}
