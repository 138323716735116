import React from "react";
import _ from "lodash";
import ProjectImage from "./ProjectImage";

const DoubleImage = props => {
  const firstProps = {
    section: {
      content_img_path: _.get(props, "section.content_img_path_1"),
      alt_text: _.get(props, "section.alt_text_1")
    }
  };
  const secondProps = {
    section: {
      content_img_path: _.get(props, "section.content_img_path_2"),
      alt_text: _.get(props, "section.alt_text_2")
    }
  };

  return (
    <section className={`double-image`}>
      <ProjectImage {...firstProps} />
      <ProjectImage {...secondProps} />
    </section>
  );
};

export default DoubleImage;
