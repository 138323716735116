import React from "react";
import _ from "lodash";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import { safePrefix } from "../utils";

const ProjectImage = props => {
  const allImages = useStaticQuery(graphql`
    query ImageQuery {
      images: allFile(filter: { extension: { regex: "/jpeg|jpg|png/" } }) {
        edges {
          node {
            extension
            relativePath
            childImageSharp {
              fluid(maxWidth: 1400, quality: 75) {
                aspectRatio
                base64
                sizes
                src
                srcSet
              }
            }
          }
        }
      }
    }
  `);

  const { images } = allImages;

  const imageData = images.edges.find(image => {
    return (
      image.node.relativePath ===
      props.section.content_img_path.split("/images/")[1]
    );
  });

  return (
    <section
      className={`block project-image outer ${
        _.get(props, "truncated") ? "truncated" : ""
      }`}
    >
      <div className="inner">
        {imageData ? (
          <Img
            fluid={{
              ...imageData.node.childImageSharp.fluid
            }}
            alt={_.get(props, "section.alt_text")}
          />
        ) : (
          <img
            src={safePrefix(_.get(props, "section.content_img_path"))}
            alt={_.get(props, "section.alt_text")}
            loading="lazy"
          />
        )}
      </div>
    </section>
  );
};

export default ProjectImage;
