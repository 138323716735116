import React from "react";
import _ from "lodash";

import { markdownify } from "../utils";

export default class ProjectText extends React.Component {
  render() {
    return (
      <section className="block text-block outer">
        <div className="inner">
          <div className="block-copy">
            {markdownify(_.get(this.props, "section.content"))}
          </div>
        </div>
      </section>
    );
  }
}
