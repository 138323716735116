import React from "react";

import { ProjectHeader, ProjectImage } from "../components";

export default class ProjectHero extends React.Component {
  render() {
    const {
      heroTitle,
      heroSubtitle,
      heroContent,
      heroImage,
      heroImageAltText,
      truncated
    } = this.props;

    return (
      <div>
        <ProjectHeader
          section={{
            title: heroTitle,
            subtitle: heroSubtitle,
            content: heroContent
          }}
        />
        <ProjectImage
          section={{ content_img_path: heroImage, alt_text: heroImageAltText }}
          truncated={truncated}
        />
      </div>
    );
  }
}
