import React from "react";
import _ from "lodash";

import { markdownify } from "../utils";

export default class ProjectHeader extends React.Component {
  render() {
    return (
      <section className="block project-header-block outer">
        <div className="inner">
          <div className="block-headers">
            <div className="block-header">
              <h1 className="block-title">
                {_.get(this.props, "section.title")}
              </h1>
            </div>
            <div className="block-subtitle">
              {markdownify(_.get(this.props, "section.subtitle"))}
            </div>
          </div>
          <div className="block-content">
            <div className="block-copy">
              {markdownify(_.get(this.props, "section.content"))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
