import React from "react";
import _ from "lodash";

import components from "../components";

export default class ProjectContent extends React.Component {
  render() {
    return (
      <div className="project-sections">
        {_.map(_.get(this.props, "sections"), (section, section_idx) => {
          let GetSectionComponent = components[_.get(section, "component")];
          return (
            <GetSectionComponent
              key={section_idx + JSON.stringify(section)}
              {...this.props}
              section={section}
            />
          );
        })}
      </div>
    );
  }
}
