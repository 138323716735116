import React from "react";
import Link from "./link";

const SocialLink = ({ title, type, url }) => {
  switch (type) {
    case "phone":
    case "email":
      return <Link to={`mailto:${url}`}>{title}</Link>;
    case "instagram":
    case "linkedin":
    case "jellyfish":
    default:
      return <Link to={url}>{title}</Link>;
  }
};

export default SocialLink;
